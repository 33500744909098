import React, { Component } from 'react';
import Header from "../component/header/HeaderSix";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Footer from "../component/footer/FooterTwo";

 class error404 extends Component {
    
    render() {
        return (
            <div className="active-dark">
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                {/* Start Page Error  */}
                <div className="error-page-inner bg_color--8">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
                                    <h1 className="title theme-gradient">404!</h1>
                                    <h3 className="sub-title">Página não encontrada</h3>
                                    <span>A página que você estava procurando não foi encontrada.</span>
                                    <div className="error-button">
                                        <a className="rn-button-style--2" href="/">Voltar à página inicial</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Error  */}
            </div>
        )
    }
}
export default error404;
