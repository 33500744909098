import React, { Component } from "react";

class About extends Component{
    render(){
        let title = 'Quem somos?',
        description = 'Somos uma empresa especializada no desenvolvimento de tecnologias e aplicações, comprometida em oferecer soluções personalizadas para seu negócio. Nossa equipe, formada por especialistas em diversas áreas da tecnologia da informação, colabora de forma estreita com você para assegurar que sua visão se transforme em realidade.';
        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">

                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about-10.jpg" alt="About Images"/>
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">{title}</h2>
                                        <p className="description">{description}</p>
                                    </div>
                                    <div className="row mt--30 mt_sm--10">
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">Informações</h3>
                                                <p>
                                                    contato@craftxp.com.br
                                                    <br/>
                                                    Rio de Janeiro - RJ
                                                    <br/>
                                                    00.000.000/0000-00
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12 d-flex align-items-center">
                                            <div className="service-btn">
                                                <a className="btn-transparent rn-btn-dark" href="https://api.whatsapp.com/send?phone=5511977130776"><span className="text">Entrar em contato</span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default About;