import React, { Component } from "react";
import Slider from "react-slick";
import { portfolioSlick2 } from "../../../page-demo/script";
import {Link} from "react-router-dom";


const PortfolioList = [
    {
        image: 'image-1',
        category: 'Computação em nuvem',
        title: 'Projetamos e implantamos toda sua infraestrutura auto escalável em nuvem.'
    },
    {
        image: 'image-2',
        category: 'Infraestrutura na Google Cloud',
        title: 'Projetamos e implantamos toda suas infraestrutura na google cloud.'
    },
    {
        image: 'image-3',
        category: 'Web',
        title: 'Desenvolvimento de aplicações Web para o seu negócio.'
    },
    {
        image: 'image-4',
        category: 'Blockchain e Web 3.0',
        title: 'Descentralize sua aplicações integrando ela a uma blockchain.'
    },
    {
        image: 'image-5',
        category: 'Aplicativo Mobile',
        title: 'Coloque suas ideias em um aplicativo.'
    },
    {
        image: 'image-6',
        category: 'RPA',
        title: 'Automatize tarefas rotineiras e ganhe mais performance.'
    }
]

class Portfolio extends Component{
    render(){
        let title = 'Como podemos ajudar?'
        return(
            <React.Fragment>
                <div className="portfolio-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title">
                                    <h2>{title}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="portfolio-slick-activation mt--70 mt_sm--40">
                        <Slider {...portfolioSlick2}>
                            {PortfolioList.map((value , index) => (
                                <div className="portfolio" key={index}>
                                    <div className="thumbnail-inner">
                                        <div className={`thumbnail ${value.image}`}></div>
                                        <div className={`bg-blr-image ${value.image}`}></div>
                                    </div>
                                    <div className="content">
                                        <div className="inner">
                                            <p>{value.category}</p>
                                            <h4 className="title">{value.title}</h4>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Portfolio;