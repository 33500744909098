import React, { Component } from "react";
import { FaInstagram , FaLinkedinIn, FaDiscord, FaBehance } from "react-icons/fa";

const SocialShare = [
    {Social: <FaDiscord /> , link: 'https://discord.gg/craft-xp-528980454137790464'},
    {Social: <FaLinkedinIn /> , link: 'https://www.linkedin.com/company/craft-xp/'},
    {Social: <FaInstagram /> , link: 'https://www.instagram.com/craft_xp/'},
    {Social: <FaBehance /> , link: 'https://www.behance.net/'},
]

class Footer extends Component{
    render(){
        return(
            <React.Fragment>
                <footer className="footer-area">
                    <div className="footer-wrapper">
                        <div className="row align-items-end row--0">
                            <div className="col-lg-6">
                                <div className="footer-left">
                                    <div className="inner">
                                        <h2>Venha fazer parte <br />do futuro</h2>
                                        <a className="rn-button-style--2" href="https://api.whatsapp.com/send?phone=5511977130776">
                                            <span>Contate-nos</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="footer-right" data-black-overlay="6">
                                    <div className="row">
                                        {/* Start Single Widget  */}
                                        <div className="col-lg-6 col-sm-6 col-12">
                                            <div className="footer-link">
                                                <h4>Acesso rápido</h4>
                                                <ul className="ft-link">
                                                    <li><a href="/embreve">Portfolio</a></li>
                                                    <li><a href="#sobre-nos">Sobre nós</a></li>
                                                    <li><a href="https://api.whatsapp.com/send?phone=5511977130776">Vamos conversar</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        {/* End Single Widget  */}
                                        {/* Start Single Widget  */}
                                        <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                                            <div className="footer-link">
                                                <h4>Say Hello</h4>
                                                <ul className="ft-link">
                                                    <li><a href="mailto:contato@craftxp.com.br">contato@craftxp.com.br</a></li>
                                                    <li><a href="mailto:rh@craftxp.com.br">rh@craftxp.com.br</a></li>
                                                </ul>

                                                <div className="social-share-inner">
                                                    <ul className="social-share social-style--2 d-flex justify-content-start liststyle mt--15">
                                                        {SocialShare.map((val , i) => (
                                                            <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                                        ))}
                                                    </ul>
                                                </div> 
                                            </div>
                                        </div>
                                        {/* End Single Widget  */}

                                        <div className="col-lg-12">
                                            <div className="copyright-text">
                                                <p>Copyright © 2022 Craft XP. Todos os direitos reservados.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        )
    }
}
export default Footer;