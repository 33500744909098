import React, { Component } from "react";

const ServiceList = [
    {
        icon: '01',
        title: 'Experiência do usuário',
        description: 'A experiência do usuário é nossa prioridade. Nosso objetivo é criar aplicações intuitivas e agradáveis, que superem as expectativas dos nossos clientes.'
    },
    {
        icon: '02',
        title: 'Análise de requisitos',
        description: 'Nos concentramos em otimizar nossas soluções de software para atender a todos os requisitos do projeto. Nosso objetivo é entregar soluções eficientes, dentro do prazo e do orçamento.'
    },
    {
        icon: '03',
        title: 'Inovação',
        description: 'Utilizamos as mais novas tecnologias e tendências do mercado. Estamos comprometidos em fornecer soluções tecnológicas de alto nivel e escalabilidade.'
    },
]

class ServiceOne extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceList.map( (val , i) => (
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                            <div className="service service__style--1">
                                <div className="icon">
                                    <img src={`/assets/images/icons/icon-${val.icon}.png`} alt="Digital Agency"/>
                                </div>
                                <div className="content">
                                    <h4 className="title">{val.title}</h4>
                                    <p>{val.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceOne;