import React, { Component } from "react";

class FormOne extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rnName: '',
            rnEmail: '',
            rnSubject: '',
            rnMessage: '',
        };
    }
    render() {
        return (
            <div className="contact-form--1">
                <div className="container" style={{maxWidth: '500px'}}>
                    <div className="section-title text-center mb--50" style={{textAlign: 'center'}}>
                        <h2 className="title" style={{color: '#E3E3E3', lineHeight: '1'}}>{this.props.title}</h2>
                    </div>
                    <div className="form-wrapper">
                        <label>
                            <a
                                className="rn-button-style--2"
                                style={{color: '#E0E0E0', fontWeight: 700}}
                                href="/"
                            >
                                Voltar
                            </a>
                        </label>
                        <br />
                        <form>
                            <label htmlFor="item01">
                                <input
                                    type="text"
                                    name="usuario"
                                    id="item01"
                                    value={this.state.rnName}
                                    onChange={(e) => { this.setState({ rnName: e.target.value }); }}
                                    placeholder="Usuario"
                                />
                            </label>

                            <label htmlFor="item02">
                                <input
                                    type="password"
                                    name="Senha"
                                    id="item02"
                                    value={this.state.rnEmail}
                                    onChange={(e) => { this.setState({ rnEmail: e.target.value }); }}
                                    placeholder="Senha"
                                />
                            </label>
                            <br />
                            <label>
                                <input
                                    type="submit"
                                    value="Entrar"
                                    className="rn-button-style--2"
                                    style={{color: '#E0E0E0', fontWeight: 700}}
                                />
                            </label>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}
export default FormOne;