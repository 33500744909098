import React, { Component } from "react";
import {Helmet} from 'react-helmet'

class PageHelmet extends Component{
    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>Craft XP</title>
                    <meta name="description" content="Somos uma empresa de desenvolvimento de tecnologias e aplicações comprometida em fornecer soluções personalizadas para o seu negócio. Nossa equipe de especialistas em diversas areas da informática trabalha em estreita colaboração com você para garantir que sua visão seja transformada em realidade." />
                </Helmet>
            </React.Fragment>
        )
    }
}


export default PageHelmet;
