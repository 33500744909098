import React, { Component } from 'react';
import Header from "../component/header/HeaderSix";
import FormOne from './form/FormOne';

class Login extends Component {
    render() {
        return(
            <main className="container-fluid bg_color--8" style={{minHeight: '100vh'}}>
                <div className="rn-contact-form-area ptb--120">
                    <FormOne title="Painel de controle" contactImages="/assets/images/about/about-6.jpg" />
                </div>
            </main>
        )
    }
}

export default Login;